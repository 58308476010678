/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import AppearOnScroll from "~components/AppearOnScroll";
import Parallaxer from "~components/Parallaxer";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

const IndexPage = ({ data, location }) => {
  const { setBackground } = useContext(AppContext);
  const { frontmatter } = data.markdownRemark;
  const projects = [];

  data.allMarkdownRemark.edges.forEach(({ node }) => {
    if (
      node?.frontmatter?.featured ||
      node?.frontmatter?.templateKey === `site-page`
    ) {
      projects.push(node);
    }
  });

  //

  projects.sort((a, b) => {
    const priorityA = a.frontmatter.priority;
    const priorityB = b.frontmatter.priority;

    if (priorityA < priorityB) {
      return -1;
    }

    if (priorityA > priorityB) {
      return 1;
    }

    return 0;
  });

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="index-page w-full relative pt-24">
        <ul className="grid pb-32">
          {projects.map(project => {
            let widthClass;

            switch (project.frontmatter.imageSize) {
              case `xl`:
                widthClass = `w-5/6 xs:w-full`;
                break;

              case `l`:
                widthClass = `w-3/4 xs:w-full`;
                break;

              case `m`:
                widthClass = `w-1/2 xs:w-5/6`;
                break;

              case `s`:
                widthClass = `w-1/3 xs:w-5/6`;
                break;

              default:
                widthClass = `w-full`;
                break;
            }

            return (
              <li className="index-page__link animation-appear-down grid-end-10 xs:grid-end-12 grid-start-2 xs:grid-start-1 relative block mt-48 xs:mt-24 mb-48 xs:mb-12">
                <AppearOnScroll
                  atTop
                  onVisible={() =>
                    setBackground(project.frontmatter.backgroundColor)
                  }
                >
                  <Link to={project.fields.slug}>
                    {(project?.frontmatter?.image?.childImageSharp?.fluid && (
                      <Img
                        className={`${widthClass} relative block mx-auto`}
                        fluid={project.frontmatter.image.childImageSharp.fluid}
                        alt={project.frontmatter.title}
                      />
                    )) || (
                      <>
                        {project?.frontmatter?.bannerImage?.childImageSharp
                          ?.fluid && (
                          <Img
                              className="w-full h-full relative block mx-auto"
                            fluid={
                                project.frontmatter.bannerImage.childImageSharp
                                  .fluid
                              }
                              alt={project.frontmatter.title}
                          />
                          )}
                      </>
                    )}

                    <Parallaxer>
                      <h2 className="w-full b1 b1--serif text-center">
                        {project.frontmatter.title}
                      </h2>

                      <h3 className="w-full b1 b1--serif text-center">
                        {project.frontmatter.description}
                      </h3>
                    </Parallaxer>
                  </Link>
                </AppearOnScroll>
              </li>
            );
          })}
        </ul>
      </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { in: ["project-page", "site-page"] } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            templateKey
            title
            priority
            featured
            backgroundColor
            description
            bannerImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            imageSize
          }
        }
      }
    }
  }
`;
